import { gsap } from 'gsap';
import WheelIndicator from 'wheel-indicator';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

import { mix } from 'utils';

import swipeable from './swipeable';

gsap.registerPlugin(ScrollToPlugin);

export default class SectionScroll {
  constructor(container, slidesLength) {
    this.container = container;
    this.isAnimating = false;
    this.pageHeight = window.innerHeight;

    this.slidesLength = slidesLength - 1;
    this.currentSlide = 0;
    this.positionProgress = 0;
    this.currentPercent = 0;
    this.isSwiping = false;

    gsap.ticker.add(this.onUpdate);
    window.addEventListener('keydown', this.onKeyPress);
    window.addEventListener('resize', this.onResize);

    this.indicator = new WheelIndicator({
      callback: (e) => {
        this.onChangeSlide(e.direction === 'down' ? 1 : -1);
      },
    });

    swipeable(this.container, {
      swiping: ({ deltaY }) => {
        if (!this.isSwiping) {
          this.isSwiping = true;
          if (Math.abs(deltaY) >= 5) {
            this.onChangeSlide(deltaY > 0 ? 1 : -1);
          }
        }
      },
      swiped: () => {
        if (this.isSwiping) {
          this.isSwiping = false;
        }
      },
    });
  }

  onResize = () => {
    this.pageHeight = window.innerHeight;
  };

  onUpdate = () => {
    const dt = 1.0 - Math.pow(1.0 - 0.1, gsap.ticker.deltaRatio());

    this.currentPercent = mix(
      this.currentPercent,
      this.positionProgress,
      dt * 0.5
    );

    gsap.set(document.body, {
      scrollTo: this.currentPercent * this.pageHeight,
    });

    // this.container.scrollTop = document.body.scrollTop =
    //   this.currentPercent * this.pageHeight;
  };

  onKeyPress = (event) => {
    switch (event.code) {
      case 'ArrowDown':
        event.preventDefault();
        this.onChangeSlide(1);
        break;
      case 'ArrowUp':
        event.preventDefault();
        this.onChangeSlide(-1);
        break;
      default:
        break;
    }
  };

  onChangeSlide = (inc) => {
    const nextSlide = gsap.utils.clamp(
      0,
      this.slidesLength,
      this.currentSlide + inc
    );

    const dist = Math.abs(Math.round(this.positionProgress) - nextSlide);

    if (this.currentSlide !== nextSlide && dist <= 1) {
      this.currentSlide = nextSlide;

      gsap.to(this, {
        positionProgress: this.currentSlide,
        duration: 3,
        ease: 'power2.out',
      });
    }
  };
}
